import React, { useEffect, useState } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
// Importing the images
import gtm_logo from "../images/googleTagManagerLogo.png";
import ga_logo from "../images/googleAnalyticsLogo.png";
import amplitude_logo from "../images/amplitudeLogo.png";
import data_logo from "../images/googleDataStudio.png";
import segment_logo from "../images/segmentLogo.png";
import Calendly from "../components/Calendly"
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


const PackagesPage = (props) => {
  // Load stripe Key from development variable
  let stripeKey = process.env.GATSBY_stripe_key
  let domain = process.env.GATSBY_site_domain
  // console.log(stripeKey)
  console.log(domain)
  // Add the stripe elements (the stripe library lives in the window.object thanks to the plugin
  // let stripe;
  // useEffect(() => {
  //   stripe = window.Stripe(stripeKey);
  // });


  // const redirectToCheckout = async () => {
  //   const { error } = await stripe.redirectToCheckout({
  //     items: [{sku: 'sku_Fi7pE4bWpFmN2k', quantity: 1}],
  //     // Do not rely on the redirect to the successUrl for fulfilling
  //     // purchases, customers may not always reach the success_url after
  //     // a successful payment.
  //     // Instead use one of the strategies described in
  //     // https://stripe.com/docs/payments/checkout/fulfillment
  //     successUrl: domain +'/success',
  //     cancelUrl: domain +'/canceled',
  //   })
  //   console.warn(error)
  // }

  // MODAL: Handle visibility
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
  <Layout>
    <SEO title="Packages and Pricing" />
    <div className={"row"}>
      <Modal show={show}
             onHide={handleClose}
             size="lg"
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
          <Calendly />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
    <div className={"row"} >
      <div className={"col"}>
        <div className={"price-box"}>
          <h2>Startup</h2>
          <h3>Set your data collection right</h3>
          <p>You want to measure if you have product-market fit, understand your users and start growing your marketing channels with guarantees</p>
          <div className={"price-list"}>
            <h4>What is included</h4>
            <ol>
              <li>Measurement Plan</li>
              <li>KPI definition</li>
              <li>Adwords & GSC integrations</li>
              <li>Funnels</li>
              <li>Internal Search tracking</li>
              <li>Email Marketing tracking</li>
              <li>Data recollection audit</li>
            </ol>
          </div>
          <div className={"price-included"}>
            <h4>Implementations</h4>
            <ol>
              <li><img src={gtm_logo} alt="Google Tag Manager" className={"mini-logos"} />Google Tag Manager</li>
              <li><img src={ga_logo} alt="Google Analyticsr" className={"mini-logos"} />Google Analytics</li>
            </ol>
          </div>
          {/*<div className={"price-details"}>*/}
            {/*<div className={"row justify-content-center"}>*/}
              {/*<div className={"col text-center"}>*/}
                {/*<h4>investment</h4>*/}
                {/*<p className={"figure"}>$3K</p>*/}
                {/*<p className={"specification"}>Contract</p>*/}
              {/*</div>*/}
              {/*<div className={"col text-center"}>*/}
                {/*<h4>Duration</h4>*/}
                {/*<p className={"figure"}>5-7</p>*/}
                {/*<p className={"specification"}>Days</p>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
          <div className={"text-center"}>
            <button onClick={handleShow} className={"btn btn-primary"} id={"btn_package_starter"}>Schedule a FREE consultation</button>
          </div>
        </div>

      </div>
      <div className={"col"}>
        <div className={"price-box shadow"}>
          <h2>Seeded</h2>
          <h3>Investing in better decisions</h3>
          <p>You are in a serious path to grow your company. Understanding your LTV or cohorts starts being paramount. Your investment in marketing and optimization of your budget is key.</p>
          <div className={"price-list"}>
            <h4>What is included</h4>
            <ol>
              <li>All startup features</li>
              <li>CRM integration</li>
              <li>Selected data warehouse integration</li>
              <li>Enhanced Ecommerce</li>
              <li>Product features measurement</li>
              <li>Testing framework setup</li>
              <li>React / OPA instrumentalization</li>
            </ol>
          </div>
          <div className={"price-included"}>
            <h4>Implementations</h4>
            <ol>
              <li><img src={gtm_logo} alt="Google Tag Manager" className={"mini-logos"} />Google Tag Manager</li>
              <li><img src={ga_logo} alt="Google Analytics" className={"mini-logos"} />Google Analytics</li>
              <li><img src={amplitude_logo} alt="Amplitude" className={"mini-logos"} />Amplitude</li>
              <li><img src={data_logo} alt="Google Data Studio implementation" className={"mini-logos"} />Google Data Studio</li>
              <li><img src={segment_logo} alt="Segment" className={"mini-logos"} />Segment</li>
            </ol>
          </div>
          {/*For some reasons this is not being taken by the commit*/}
          {/*<div className={"price-details"}>*/}
            {/*<div className={"row justify-content-center"}>*/}
              {/*<div className={"col text-center"}>*/}
                {/*<h4>investment</h4>*/}
                {/*<p className={"figure"}>$7K</p>*/}
                {/*<p className={"specification"}>Contract</p>*/}
              {/*</div>*/}
              {/*<div className={"col text-center"}>*/}
                {/*<h4>Duration</h4>*/}
                {/*<p className={"figure"}>2-4</p>*/}
                {/*<p className={"specification"}>Weeks</p>*/}
              {/*</div>*/}
            {/*</div>*/}
          {/*</div>*/}
          {/*STRIPE BUTTONS HERE -------------------->*/}
          <div className={"text-center"}>
            <button onClick={handleShow} className={"btn btn-primary"} id={"btn_package_seeded"}>Schedule a FREE consultation</button>
          </div>

          {/* STRIPE BUTTON END*/}
        </div>

      </div>
      <div className={"col"}>
        <div className={"price-box"}>
          <h2>Custom</h2>
          <h3>You want solutions made just for you</h3>
          <p>Your measure need to scale up with your company and your growth. You enter the realm of data science, big data and you need an experienced team on-demand.</p>
          <div className={"price-list"}>
            <h4>We can help you with...</h4>
            <ol>
              <li>Big Data management</li>
              <li>Forecasting</li>
              <li>Quantitative research</li>
              <li>Testing at scale</li>
              <li>Custom model attribution</li>
              <li>Cookie enrichment</li>
              <li>Dynamic Audiences</li>
              <li>Complex migrations</li>
            </ol>
          </div>
          {/*STRIPE BUTTONS HERE -------------------->*/}
          <div className={"text-center"}>
            <button onClick={handleShow} className={"btn btn-primary"} id={"btn_package_custom"}>Schedule a FREE consultation</button>
          </div>

          {/* STRIPE BUTTON END*/}
        </div>

      </div>
    </div>
  </Layout>
)
}

export default PackagesPage
